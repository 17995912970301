import React, {forwardRef, useEffect} from 'react';
import {Box} from "@mui/material";
import {isSecure} from "../../common/utils";

const VastPlayer = forwardRef(( ({
                                   src,
                                   width,
                                   height,
                                   vastUrl,
                                   autoPlay = false,
                                   handleTimeUpdate
                                 }, ref) => {
  useEffect(() => {
    const player = window.videojs('vast-video');

    const companion = {
      elementId: "companion"
    }

    if (isSecure()) {
      player.vast({url: vastUrl, companion: companion});
    }
  }, [vastUrl]);

  return (
    <Box
      sx={{
        width: width,
        height: height,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
      }}
    >
      <video
        id={'vast-video'}
        className="video-js"
        src={src}
        controls
        controlsList="nodownload"
        autoPlay={autoPlay}
        //onContextMenu={(e) => e.preventDefault()}
        onTimeUpdate={handleTimeUpdate}
      />
      <div id="companion"></div>
    </Box>
  );
}));

export default VastPlayer;
