import React, { useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Button, ButtonGroup, Grid, Paper, Typography, Box } from '@mui/material'
import { Helmet } from 'react-helmet'
import CopyToClipboard from 'react-copy-to-clipboard'
import LinkIcon from '@mui/icons-material/Link'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import SnackbarAlert from '../components/alert/SnackbarAlert'
import NotFound from './NotFound'
import { VideoService } from '../services'
import { getUrl, getPublicWatchUrl, copyToClipboard } from '../common/utils'
import VastPlayer from "../components/video/VastPlayer";

const URL = getUrl()
const PURL = getPublicWatchUrl()

function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

const Watch = ({ authenticated }) => {
  const { id } = useParams()
  const query = useQuery()
  const time = query.get('t')
  const [details, setDetails] = React.useState(null)
  const [notFound, setNotFound] = React.useState(false)
  const [views, setViews] = React.useState()
  const [viewAdded, setViewAdded] = React.useState(false)

  const videoPlayerRef = useRef(null)
  const [alert, setAlert] = React.useState({ open: false })

  React.useEffect(() => {
    async function fetch() {
      try {
        const resp = (await VideoService.getVideo(id))
        setDetails(resp)
      } catch (err) {
        if (err.response && err.response.status === 404) {
          setNotFound({
            title: "We're Sorry...",
            body: "But the video you're looking for was not found.",
          })
        } else {
          setNotFound({
            title: 'Oops!',
            body: 'Something somewhere went wrong.',
          })
        }
      }
    }
    if (details == null) fetch()
  }, [details, id])

  const copyTimestamp = () => {
    console.log(videoPlayerRef.current)
    copyToClipboard(`${PURL}${details?.id}?t=${videoPlayerRef.current?.getCurrentTime()}`)
    setAlert({
      type: 'info',
      message: 'Time stamped link copied to clipboard',
      open: true,
    })
  }

  if (notFound) return <NotFound title={notFound.title} body={notFound.body} />

  const controls = () => (
    <ButtonGroup variant="contained" sx={{ maxWidth: '100%' }}>
      <CopyToClipboard text={`${PURL}${details?.id}`}>
        <Button
          onClick={() =>
            setAlert({
              type: 'info',
              message: 'Link copied to clipboard',
              open: true,
            })
          }
        >
          <LinkIcon />
        </Button>
      </CopyToClipboard>
      {/*<Button onClick={copyTimestamp}>*/}
      {/*  <AccessTimeIcon />*/}
      {/*</Button>*/}
      <Button
        disabled
        sx={{
          '&.Mui-disabled': {
            borderRight: 'none',
            borderTop: 'none',
          },
        }}
      >
        <div
          style={{
            overflow: 'hidden',
            color: 'white',
            textTransform: 'uppercase',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {details?.id}
        </div>
      </Button>
    </ButtonGroup>
  )

  return (
    <>
      <SnackbarAlert severity={alert.type} open={alert.open} setOpen={(open) => setAlert({ ...alert, open })}>
        {alert.message}
      </SnackbarAlert>
      <Helmet>
        <title>{((details && details.performer + ' ') || '') + ' (Coomer Pub)'}</title>
        <meta property="title" content={(details && details.performer + ' ') + 'exclusive video'}/>
        <meta property="url" content={PURL + details?.id}/>
        <meta property="description" content={(details && details.performer + ' ') + 'exclusive content on Coomer Pub!'}/>
        <meta
          property="og:image"
          value={
            `${URL}${details?.details?.thumbnail}`
          }
        />
        <meta
          property="og:video"
          value={
            `${URL}${details?.details?.path}`
          }
        />
        <meta property="og:site_name" value="Coomer Pub"/>
        <meta property="og:title" value={(details && details.performer + ' ') + 'exclusive OnlyFans video'}/>
        <meta property="og:url" value={PURL + details?.id}/>
        <meta property="og:description" value={(details && details.performer + ' ') + 'exclusive content on Coomer Pub!'}/>
        <meta property="og:type" value="video"/>
        <meta property="og:video:width" value={details?.details?.width}/>
        <meta property="og:video:height" value={details?.details?.height}/>
      </Helmet>
      <Grid container>
        <Grid item xs={12}>
        {
            details &&
            <VastPlayer
              ref={videoPlayerRef}
              width="100%"
              height="calc(100vh - 164px)"
              src={`${URL}${details.details.path}`}
              vastUrl="https://s.magsrv.com/v1/vast.php?idzone=5438038"
            />
          }
        </Grid>
        <Grid item xs={12}>
          <Paper width="100%" square sx={{ p: 1, mt: '-6px', background: 'rgba(0, 0, 0, 0.1)' }}>
            <Box sx={{ display: { xs: 'none', sm: 'flex' }, mr: 1 }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  {controls()}
                </Grid>
                {details?.details?.description && (
                  <Grid item xs={12}>
                    <Paper sx={{ width: '100%', p: 2, background: 'rgba(255, 255, 255, 0.12)' }}>
                      <Typography variant="subtitle2">{details?.details?.description}</Typography>
                    </Paper>
                  </Grid>
                )}
              </Grid>
            </Box>
            <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  {controls()}
                </Grid>
                {details?.details?.description && (
                  <Grid item xs={12}>
                    <Paper sx={{ width: '100%', p: 2, background: 'rgba(255, 255, 255, 0.12)' }}>
                      <Typography variant="subtitle2">{details?.details?.description}</Typography>
                    </Paper>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}

export default Watch
