import React, {useCallback, useEffect, useState} from 'react'
import {Box, Grid, Paper, Typography} from '@mui/material'
import SnackbarAlert from '../alert/SnackbarAlert'
import VisibilityCard from './VisibilityCard'
import VideoModal from '../modal/VideoModal'
import LoadingSpinner from "../misc/LoadingSpinner";
import {VideoService} from "../../services";
import {useDetectAdBlock} from "adblock-detect-react";

const VideoCards = ({
                      group,
                      sort,
                      query,
                      size
                    }) => {
  const [vids, setVideos] = useState([])
  const [alert, setAlert] = useState({open: false})
  const [videoModal, setVideoModal] = useState({
    open: false,
  })
  const [loading, setLoading] = useState(false)

  const adBlockDetected = useDetectAdBlock()

  const openVideo = (id) => {
    if (adBlockDetected) {
      setAlert({
        open: true,
        message: 'Please disable your ad blocker to view videos.',
        type: 'warning',
        onClose: () => setAlert({open: false}),
      })
      return
    }

    setVideoModal({
      open: true,
      id,
    })
  }

  const onModalClose = () => {
    setVideoModal({open: false, id: undefined})
  }

  const handleNext = () => {
    const currentIndex = vids.findIndex((v) => v.id === videoModal.id)

    if (currentIndex < vids.length - 1) {
      setVideoModal({
        open: true,
        id: vids[currentIndex + 1].id,
      })
    } else {
      setVideoModal({
        open: false,
        id: undefined,
      })
    }
  }

  const memoizedHandleAlert = useCallback((alert) => {
    setAlert(alert)
  }, [])

  const fetch = async () => {
    setLoading(true)

    try {
      const videos = await VideoService.getVideos(group, sort, query)

      setVideos(videos)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    fetch().then()
  }, [group, sort])

  const EMPTY_STATE = () => (
    <Paper variant="outlined" sx={{overflow: 'hidden'}}>
      <Grid
        sx={{p: 2, height: 200}}
        container
        item
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        {!loading && (
          <>
            <Grid item>
              <Typography
                variant="h4"
                align="center"
                color="primary"
                sx={{
                  fontFamily: 'monospace',
                  fontWeight: 500,
                  letterSpacing: '.2rem',
                  textDecoration: 'none',
                }}
              >
                NO VIDEOS FOUND
              </Typography>
            </Grid>
          </>
        )}
        <LoadingSpinner/>
      </Grid>
    </Paper>
  )

  return (
    <Box>
      <VideoModal
        open={videoModal.open}
        onClose={onModalClose}
        onNext={handleNext}
        group={group}
        videoId={videoModal.id}
        feedView={true}
      />
      <SnackbarAlert
        severity={alert.type}
        open={alert.open}
        onClose={alert.onClose}
        setOpen={(open) => setAlert({...alert, open})}
      >
        {alert.message}
      </SnackbarAlert>

      {(!vids || vids.length === 0) && EMPTY_STATE()}
      {vids && vids.length !== 0 && (
        <Grid container justifyContent="center">
          {vids.map((v) => (
            <VisibilityCard
              key={v.path + v.id}
              video={v}
              handleAlert={memoizedHandleAlert}
              openVideo={openVideo}
              cardWidth={size}
            />
          ))}
        </Grid>
      )}
    </Box>
  )
}

export default VideoCards
